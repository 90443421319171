*{
    padding: 0;
    margin: 0;
}

.sign-in-form .inner form{
    display: inline;
    flex-direction: column;
    justify-content: center;
    justify-self: center;
    align-items: center;
    align-content: center;
}

.zikora-icon{
    width: 100%;
    display: inline-flex;
    margin: 20px 0px;
    justify-content: center;
    align-items: center;
}


.sign-in-form{
    width: 100%;
    overflow-y: hidden;
    display: inline-flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    margin: 0;
}


.sign-in-form .inner{
    width: 100%;
}

.double-row{
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    
}

.double-row .row1{
    width: 40%;
    padding: 50px;
    flex: 1;
    display: inline-flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}
.double-row .row1 form{
    width: 40%;
}

.double-row .row1 .checklayout{
    display: inline-flex;
    /* width: 100%; */
    margin-top: 0px;
    font-size: 14px;
    justify-content: flex-start;
    width: 100%;
    text-align: start;
    align-items: center;
 
}
.double-row .row1 .checklayout input{
    width: unset;
    margin-right: 10px;
    padding: 0px;
    
}

.double-row .row1 form input, .double-row .row1 form button{
    width: 100%;
    
}

.double-row .row1 label{
    text-align: left;
    color: #000;
}





.double-row .row2{
    width: 40%;
    height: 100vh;
    background-color: #fff;
    flex: 1;
    color: #608E75;
    align-items: center;
    border-left: 1px solid #ccc ;
}

.double-row .in{
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.double-row .row2 .header{
    height: 20vh;
    margin-top: 10vh;
    justify-content: start;
    display: inline-flex;
    align-items: flex-start;
    flex-direction: column;
    width: 90%;
    text-align: left;
}

.double-row .row2 .header h1{
    letter-spacing: 2px;
}

.double-row .row2 .header p{
    color: #4C6356;
    padding: 10px 0px;
    width: 70%;
    text-align: start;
}

.double-row .row2 .img{
    width: 100%;
}

.double-row .row2 .img img{
    width: 70%;
    object-fit: fill;
    object-position: 0;
    height: 70vh;
    border-top-right-radius: 60px;
}

.inner form input{
    padding: 10px 10px;
    margin: 10px 0px;
    background-color: transparent;
    border: 0px;
    text-align: start;

    font-family: 'Poppins', sans-serif;
    color: #bbbbbb;
    border-radius: 5px;
    border-bottom: 1px solid #cccc;
    width: 60%;
}

.btn{
    width: 60%;
    font-family: 'Poppins', sans-serif;
    padding: 15px;
    margin: 14px 0px;
    color: white;
    background-color: #608E75;
    border: 1px solid transparent;
    border-radius: 5px;
}
.btn:hover{
background-color: green;
}