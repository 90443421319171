.modal-form input{
    border: 1px solid #C3C3C3;
    padding: 10px;

}

.modal-form textarea{
    padding: 10px;
    height: 200px;
    border: 1px solid #C3C3C3;
}