.existing-account{
    width: 100%;
    height: 100vh;
    display: inline-flex;
    justify-content: space-between;
}

.existing-account .body{
    width: 85%;
    height: 100vh;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.existing-account .in{
    width: 94%;

}

.existing-account .in .search-area{
    padding: 15px;
    background-color: #f8f8f8f8;
    margin-top: 5vh;
    width: 40vw;
    font-size: 14px;
    border-radius: 5px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
}

.existing-account .in .search-area input{
    border: none;
    outline: none;
    padding: 5px;
    font-size: 14px;
    background-color: transparent;
    width: 38vw;
}

.existing-account .in .accounts-list{
    margin-top: 5vh;
}

.existing-account .in .accounts-list p:nth-child(1){
    margin: 2vh 2vh;
    font-size: 15px;
    color: green;
}

.existing-account .in .accounts-list .table{
    width: 100%;
}

.existing-account .in .accounts-list .table table{
    width: 100%;
}

.existing-account .in .accounts-list table thead{
    background-color: #f8f8f8f8;
    font-size: 15px;
}

.existing-account .in .accounts-list table thead td{
    padding: 20px 10px;
    font-size: 14px;
}

.existing-account .in .accounts-list table tr td{
    padding: 20px 10px;
    font-size: 14px;
}


/* .existing-account .in .account_list  table tr select{
    
} */
.existing-account .select-cont{
    padding: 5px;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
}

.existing-account .select-cont select{
    padding: 5px 10px;
    margin: 10px 0px;
    border: none;
    outline: none;
}

.existing-account .select-cont select > option{
    color: green;
    font-size: 17px;
}

.edit-icon {
    font-size: 18px;
    color:#FF4040;
    cursor: pointer;
}


@media (min-width: 800px) and  (max-width: 1068px) {
    .existing-account .body{
        width: 79%;
    } 
  }
  
  
  @media (min-width: 500px) and  (max-width: 800px) {
    .existing-account .body{
        width: 100%;
    }
  }