

    .l{width: 100%;
    height: 100vh;
    display: inline-flex;
    justify-content: space-between;
}

.onboard_user .body{
    width: 85%;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.onboard_user .body .in{
    width: 100%;
    height: 92vh;
    overflow-y: scroll;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.analog-check{
    margin-bottom: 20px;
}

.content{
    width: 90%;
    margin-top: 4vh;
}

.content p:nth-child(1){
    color: black;
    font-weight: 200;
}

.content .details{
    padding: 10px;
    margin-top: 2vh;
}

.content .details p:nth-child(1){
    color: green;
}

.content .grid{
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.content .form-input{
    display: inline-flex;
    flex-direction: column;
    margin: 20px 0px;
}

.content .form-input p{
    padding: 10px;
    color: red;
    font-size: 14px;
}

.content  label{
    margin: 7px 0px;
    font-size: 14px;
}

.content .form-input input, .content .form-input .select{
    width: 70%;
    padding: 16px;
    
    border-radius: 0px;
    border: none;
    border-bottom: 1px solid #ccc;
}

.content .form-input select{
    width: 100%;
    border-radius: 5px;
    border: none;
    outline: none;
}

.content .form-input .select > option{
    font-size: 17px;
}

.image-container .stat{
    color: red;
    font-size: 14px;
    padding: 0px 20px 20px 0px;
}

.content .grid .img{
    width: 70%;
    padding: 20px;
    border-bottom: 1px solid #ccc;
    text-align: center;
    margin-bottom: 20px;
}

.content .grid .img .label{
    color: #000;
    font-weight: 500;
    font-size: 19px
}

.sub-btn{
    background-color: #608E75;
    color: white;
    /* width: 70%; */
    font-size: 17px;
    border-radius: 10px;
    padding: 20px 40px;
    border: none;
}

.capture{
    padding: 15px;
    background-color: #608E75;
    border: none;
    border-radius: 4px;
    color: white;
}



@media (min-width: 800px) and  (max-width: 1068px) {
    .body{
        width: 79%;
    } 
  }
  
  
  @media (min-width: 500px) and  (max-width: 800px) {
    .body{
        width: 100%;
    }
  }