

 section {
    width: 80vw;
    height: 92vh;
    padding: 0px 20px;
}

 section .search-area{
    width: 40vw;
    background-color: #f8f8f8f8;
    margin-top: 2vh;
    padding: 10px 20px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
}
 section .search-area input{
    width: 35vw;
    padding: 10px;
    border: none;
    background-color: transparent;
}


section .card {
    padding: 20px;
    margin-top: 2vh;
}

section .card .in {
    display: inline-flex;
    justify-content: space-between;
    
    align-items: flex-start;
    flex-direction: column;
}


 section  .table{
    width: 100%;
    display: inline-flex;
    justify-content: flex-start;
    overflow-y: scroll;
    height: 54vh;
    align-items: flex-start;
    margin-top: 3vh;
}

 .table{
    width: 100%;
}

.empty{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 54vh;
    flex-direction: column;
}

 .table table{
    width: 100%;
}

 .table table thead{
    background-color: #f8f8f8f8;
    color: #000;
}
 .table table td{
    padding: 20px 10px;
}


