*{
    padding: 0;
    margin: 0;
}

.sign-in-form .inner form{
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    justify-self: center;
    align-items: center;
    align-content: center;
}

.zikora-icon{
    width: 100%;
    display: inline-flex;
    margin: 20px 0px;
    justify-content: center;
    align-items: center;
}


.sign-in-form{
    width: 100%;
    overflow-y: hidden;
    display: inline-flex;
    height: 100vh;
    justify-content: center;
    align-items: center;
    margin: 0;
}


.sign-in-form .inner{
    width: 100%;
}

.double-row{
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    
}

.double-row .row1{
    width: 40%;
    padding: 30px;
    flex: 1;
    display: inline-flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
}
.double-row .row1 form{
    width: 40%;
}

.double-row .row1 .checklayout{
    display: inline-flex;
    margin-top: 0px;
    justify-content: flex-start;
    width: 100%;
    text-align: start;
    align-items: center;
 
}
.double-row .row1 .checklayout input{
    width: unset;
    margin-right: 10px;
    padding: 0px;
    background: url("../../../Assets/ICONS/login.png") no-repeat;
    background-size: 20px;
    height: 10px;
    
}

.double-row .row1 form input, .double-row .row1 form button{
    width: 100%;
    
}

.double-row .row1 label{
    text-align: left;
    color: #000;
    font-size: 14px;
}





.double-row .row2{
    width: 40%;
    height: 100vh;
    background-color: #fff;
    flex: 1;
    color: #608E75;
    flex-direction: column;
    /* align-items: center; */
    border-left: 1px solid #ccc ;
}

.double-row .in{
    display: inline-flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.double-row .row2 .header{
    height: 30vh;
    /* justify-content: space-between; */
    /* display: inline-flex; */
    /* flex-direction: column; */
    /* align-items: flex-start; */
    width: 90%;
    font-size: 15px;
}

.double-row .row2 .img{
    width: 100%;
}

.double-row .row2 .img img{
    width: 70%;
    object-fit: fill;
    object-position: 0;
    height: 70vh;
    border-top-right-radius: 60px;
}

.inner form input{
    padding: 20px 10px;
    margin: 0px 0px;
    background-color: transparent;
    border: 0px;
    text-align: start;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    color: #bbbbbb;
    border-radius: 5px;
    border-bottom: 1px solid #cccc;
    width: 60%;
}

.btn{
    width: 60%;
    font-family: 'Poppins', sans-serif;
    padding: 30px;
}
.btn:hover{
background-color: green;
}

@media (min-width: 0px) and  (max-width: 860px) {
    .double-row .row2{
        display: none;
    }
    .double-row .row1{
        width: 70%;
    }
    .double-row .row1 form{
        width: 70%;
    }
    .double-row .row1 form input{
        width: 70%;
    }
  }