.get_profile {
    width: 100%;
    height: 100vh;
    display: inline-flex;
    justify-content: space-between;
}

.get_profile .body {
    width: 85%;
    z-index: 2;
    background-color: #fff;
    display: inline-flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
}

.get_profile .body section {
    width: 96%;
    height: 92vh;
}

.get_profile .card{
    background-color: white;
    /* width: 100%; */
    /* height: 10vh; */
    border-radius: 10px;
    width: 20%;
}

.get_profile .card .in{
    padding: 10px;
}

.details_card{
    width: 100%;
    margin-top: 2vh;
    background-color: #fff;
    height: 64vh;
}

.details_card .in{
    padding: 30px 30px;
    color: #608E75;
}

.profile-grid{
    margin-top: 1vh ;
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 30px;
}

.profile-grid input{
    padding: 20px;
}

.profile-grid label{
    font-size: 12px;
    color: #ccc;
    padding: 1vh 0;
}

.profile-grid .container{
    margin-top: 3vh;
}

.profile-grid .field{
    padding: 10px 0px;
    background-color: #F5F6F4;
    padding: 20px 20px;
    color: #000;
    border-radius: 5px;
}


/* .get_profile_body{
    height: 88.5vh;
    width: 100%;
    display: inline-flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: center;
}


.profile{
    height: 78.5vh;
    width: 70%;
    display: inline-flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
} */


@media (min-width: 800px) and  (max-width: 1068px) {
    .get_profile .body {
        width: 79%;
    } 
  }
  
  
  @media (min-width: 500px) and  (max-width: 800px) {
    .get_profile .body {
        width: 100%;
    }
  }