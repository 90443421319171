.users-account{
    width: 100%;
    height: 100vh;
    padding: 0;
    background-color: #fff;
    display: inline-flex;
    /* overflow-x: hidden; */
    justify-content: space-between;
    margin: 0;
}


.users-account .body{
    width: 85%;
    height: 100vh;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}

.users-account .body .in{
    width: 94%;
}

.users-account .customer{
    margin-top: 7vh;
    color: #000;
}

.users-account .customer h1{
    margin: 1vh 0;
}
.users-account .customer p:nth-child(3){
    font-size: 12px;
    color: #d1d1d1;
}

.users-account .account_list{
    margin-top: 7vh;
}
.users-account .account_list .table{
    width: 100%;
    height: 58vh;
    margin-top: 2vh;
    overflow-y: scroll;
}

.users-account .account_list p{
    margin: 2vh 2vh;
    color: green;
    font-size: 17px;
}

.users-account .account_list .table table{
    width: 100%;
}

.users-account .account_list .table table thead{
    background-color: #f8f8f8f8;
}

.users-account .account_list .table table tr{
    background-color: #fff;
}

.users-account .account_list .table table thead td{
    padding: 20px 10px;
}

.users-account .account_list .table table tr td{
    padding: 25px 10px;
    font-size:14px;
    
}

.users-account .account_list .table table select{
    padding: 5px 10px;
    margin: 25px 0px;
    border: none;
    outline: none;
}
.select-cont{
    padding: 5px;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
}
/* .users-account{
    width: 100%;
    height: 100vh;
    background: url("../../Assets/BACKGROUNDS/abstr.png");
    background-repeat: no-repeat;
    background-size: 130%;
    background-position: center;
    
    object-fit: cover;
    margin: 0;
    padding: 0;
}

p{
    font-family: 'Poppins', sans-serif;
}

.label{
    padding: 50px;
    display: inline-flex;
    justify-content: space-between;
}

.label div{
    padding: 0px 30px;
}

.contents{
    width: 85%;
    display: inline;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.row{
    width: 90%;
    justify-content: space-evenly;
    display: inline-flex;
    padding: 20px;
    color: gray;
}

select{
    background-color: transparent;
    border: 0px;
    color: gray;
} */


@media (min-width: 800px) and  (max-width: 1068px) {
    .users-account .body{
        width: 79%;
    } 
  }
  
  
  @media (min-width: 500px) and  (max-width: 800px) {
    .users-account .body{
        width: 100%;
    }
    .users-account .body .in{
        width: 96%;
    }
    .users-account .account_list .table table td, .users-account .account_list .table table td span{
        font-size: 12px;
    }
  }