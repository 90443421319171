.search .input{
    padding: 13px;
    width: 100%;
    border: none;
    background-color: transparent;
}

.small .input-small{
    padding: 0;
    width: 100%;
    border: none;
    background-color: transparent;
}

.search{
    /* background-color: #f8f8f8f8; */
    border: 1px solid #D1D1D1;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 15px;
    border-radius: 5px;
    width: 100%;
}

.small{
    /* background-color: #f8f8f8f8; */
    border: 1px solid #D1D1D1;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 3px 6px;
    border-radius: 5px;
    width: 100%;
}