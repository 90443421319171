.bordered-table{
    width: 80vw;
    height: 75vh;
    margin-top: 5vh;
    border: 1px solid #E4E4E4;
}

.bordered-table table{
    width: 100%;
}

.bordered-table table thead{
    background-color: white;
    color: #000;
    text-transform: uppercase;
}

.bordered-table table thead td{
    padding: 20px 20px;
    background-color: #fff;
    border-bottom: 1px solid grey;
    border-bottom-style: dashed;
    font-size: 14px;
    text-align: center;

}

.bordered-table tr td{
    background-color: #FCFCFC;
    padding: 20px 20px;
    font-size: 12px;
    text-align: center;
}

.bordered-table tr:nth-child(even){
    background-color: #FCFCFC;
}

.bordered-table tr:nth-child(odd){
    background-color: #FFF;
}

.bordered-table tr td .action{
    /* padding: 2px 5px; */
    padding: 1px;
    border: 1px solid rgb(213, 208, 208);
    display: inline-flex;
    border-radius: 2px;
    text-align: start;
    font-size: 16px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.user-row{
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
}

.user-row .dp{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: black;
    margin-right: 10px;
}
.user-row .dp img{
    border-radius: 100%;
    object-fit: cover;
}