.transactions{
    width: 100%;
    height: 100vh;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
}

.transactions .body{
    display: inline-flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 85%;
    height: 100vh;
    align-items: center;
}

.transactions .body .in{
    width: 94%;
}

.transactions .body .in .search input{
    padding: 13px;
    width: 100%;
    border: none;
    background-color: transparent;
}

.transactions .body .in .search{
    background-color: #f8f8f8f8;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 15px;
    border-radius: 5px;
    width: 40vw;
}

.account_no{
    margin: 5vh 2vh;
}

.account_no p:nth-child(1){
    color: black;
    letter-spacing: 2px;
    font-size: 17px;
}
.account_no p:nth-child(2){
    text-transform: uppercase;
    margin: 5px 0px;
    font-size: 14px;
}

.transaction_list{
    margin-top: 5vh;
}

.transaction_list .table{
    width: 100%;
    height: 57vh;
    overflow-y: scroll;
}

.transaction_list .table table{
    width: 100%;
    margin-top: 1vh;
}

.transaction_list .table table thead{
    background-color: #f8f8f8f8;
    color: #000;
}

.transaction_list .table table td{
    padding: 20px 10px;
}

.transaction_list .table table tr td{
    background-color: white;
}
.empty{
height: 47vh;
}


@media (min-width: 800px) and  (max-width: 1068px) {
    .transactions .body{
        width: 79%;
    } 
  }
  
  
  @media (min-width: 500px) and  (max-width: 800px) {
    .transactions .body{
        width: 100%;
    }
  }