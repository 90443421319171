.breakpoint{
    width: 100vw;
    height: 100vh;
    background-image: url("../../Assets/BACKGROUNDS/abstr.png");
    background-repeat: no-repeat;
    background-size: 290%;
    padding: 0;
    margin: 0;
    font-family: 'Poppins', sans-serif;
    display: inline-flex;
    background-color: #fff;
    color: gray;
    justify-content: center;
    align-items: center;
    background-position: center;
    overflow: hidden;
}

.message{
    font-size: 17px;
    width: 80%;
    padding: 20px 0px;
}