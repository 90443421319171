.first-row {
    margin-top: 2rem;
}

.hearder-text {
    font-size: 20px;
    font-weight: 600;
    color: #252424;
}

.sub-text {
    font-size: 12px;
    color: #252424;
}

.sub-text span {
    color: #FF4040
}