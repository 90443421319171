:root{
  --mid-tone: #8D8D8D
}
.app{
  display: block;
  background-color: white;
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: gray;
  width: 100%;
  padding: 0px;
  margin: 0px;
}

.the-body{
  width: 85%;
} 

.dialog-text{
  font-family: 'Poppins', sans-serif;
  text-align: center;
  font-size: 14px;
}

.radio-text{
  font-family: 'Poppins', sans-serif;
  font-size: 14px;
}

.radio-text label{
  display: inline-flex;
  justify-content: center;
  margin: 5px 0px;
  align-items: center;
  color: #000;
  font-weight: 400;
}
.pop{
  width: 20%;
  margin-right: 50px;
}

input{
  outline: 0px;
  border-bottom: 1px solid #BBBBBB;
}

.numba{
  font-family: Century Gothic,CenturyGothic,AppleGothic,sans-serif; 
}

.break{
  display: none;
}

.side-nav-btn{
  background-color: transparent;
  border: none;
  color: green;
  font-size: 17px;
  display: none;
}
.side-nav-hover{
  width: 25%;
  position: fixed;
  transition: 0.5s ease-in-out;
  left: 0;
  background-color: white;
  z-index: 99;
  height: 100vh;
  border-right: 1px solid grey;
  display: none;

}


/* @media only screen and  (max-width: 550px) {

    .app{
      display: none;
    }

    .break{
      display: block;
    }
  
} */

@media (min-width: 800px) and  (max-width: 1068px) {
  .the-body{
      width: 79%;
  } 
}


@media (min-width: 500px) and  (max-width: 800px) {
  .the-body{
      width: 100%;
  }
}